/*Marine*/
/*Grapefruit*/
/*Watermelon*/
/*Grey*/
/*Lime*/
/*Forest green*/
/*Aqua*/
/*Lilac*/
/*Pale pink*/
/*Cherry*/
.performance[data-v-7e3e5c55] {
  background-color: #f3f3f3;
}
.performance[data-v-7e3e5c55]  .selection-holder {
  width: 40rem !important;
}
.performance-wrapper[data-v-7e3e5c55] {
  position: relative;
  padding: 2rem 2rem 10rem;
  width: 100%;
  overflow-x: scroll;
}
.performance-wrapper .table-wrapper[data-v-7e3e5c55] {
  position: relative;
  align-items: flex-start;
}
.performance-wrapper .table-wrapper .table-inputs-wrapper[data-v-7e3e5c55] {
  flex: 1;
  padding-bottom: 1rem;
}
.performance-wrapper .save-slide-out[data-v-7e3e5c55] {
  width: 85%;
  position: fixed;
  transform: translateY(8rem);
  bottom: 10px;
  transition: transform 0.3s ease-in;
  right: 0;
}
.performance-wrapper .save-slide-out.show-save[data-v-7e3e5c55] {
  transform: translateY(0rem);
}
.performance-wrapper .save-slide-out .slide-out-inner[data-v-7e3e5c55] {
  width: 360px;
  margin: 0 auto;
  padding: 1.25rem;
  background: white;
  border-radius: 1rem 1rem 0 0;
  box-shadow: 0px 0px 8px 3px rgba(0, 0, 0, 0.17);
}
.performance-wrapper .save-slide-out .slide-out-inner .btn[data-v-7e3e5c55]:first-of-type {
  margin-right: 1rem;
}
.no-data[data-v-7e3e5c55] {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}
.centerY[data-v-7e3e5c55] {
  top: 50%;
  transform: translateY(-50%);
  height: 100%;
}
.mt-3[data-v-7e3e5c55] {
  margin-top: 2rem;
}
.mb-3[data-v-7e3e5c55] {
  margin-bottom: 2rem;
}