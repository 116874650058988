@charset "UTF-8";
/*Marine*/
/*Grapefruit*/
/*Watermelon*/
/*Grey*/
/*Lime*/
/*Forest green*/
/*Aqua*/
/*Lilac*/
/*Pale pink*/
/*Cherry*/
.header[data-v-23bba763] {
  white-space: nowrap;
  padding: 10px;
  border-bottom: 2px solid #e0e0e0;
  text-align: center;
  background-color: #f8f9fa;
  font-weight: bold;
  min-height: 5rem;
  font-size: 1.4rem;
}
.header-top[data-v-23bba763] {
  border: 0;
  align-items: center;
  justify-items: center;
  font-weight: bold;
  background-color: #f8f9fa;
}
.name[data-v-23bba763] {
  display: flex;
  align-content: center;
  align-items: center;
  gap: 0.25rem;
  font-size: 1.2rem;
  line-height: 125%;
}
.max-height[data-v-23bba763] {
  max-height: 6rem;
}
.date-column[data-v-23bba763] {
  width: 20rem;
}
.all-buttons-container[data-v-23bba763] {
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
  padding-right: 1rem;
  width: 100%;
}
.moic-column[data-v-23bba763],
.irr-column[data-v-23bba763] {
  width: calc(25% - 2.5rem);
}
.disabled[data-v-23bba763] {
  opacity: 0.5;
  pointer-events: none;
}
.moic-container[data-v-23bba763],
.irr-container[data-v-23bba763],
.date-picker-container[data-v-23bba763] {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-size: 1.2rem;
}
.radio-buttons[data-v-23bba763] {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.radio-buttons input[type=radio][data-v-23bba763] {
  display: none;
}
.radio-buttons label[data-v-23bba763] {
  display: inline-block;
  flex: 1;
  text-align: center;
  background-color: #ccc;
  border-radius: 5px;
  cursor: pointer;
  max-width: 15rem;
  margin-right: 1rem;
  padding: 0.5rem 0;
  transition: background-color 0.3s, color 0.3s;
  font-size: 1.2rem;
}
.radio-buttons input[type=radio]:checked + label[data-v-23bba763] {
  color: #fff;
  background-color: #e93f6f;
}
.date-picker-container[data-v-23bba763] {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}
.centered[data-v-23bba763] {
  text-align: center;
  width: 100%;
}
.left-border[data-v-23bba763] {
  border-left: 2px solid black;
}
.text-bold[data-v-23bba763] {
  font-weight: bold;
}
.table[data-v-23bba763] {
  width: 100%;
  border-collapse: collapse;
}
.tr[data-v-23bba763] {
  display: flex;
  border-bottom: 1px solid #e0e0e0;
}
.td[data-v-23bba763] {
  flex: 1;
  box-sizing: border-box;
  align-items: center;
  word-wrap: break-word;
  white-space: normal;
  overflow-wrap: break-word;
}
.double-width[data-v-23bba763] {
  flex: 2;
}
.fa-fw[data-v-23bba763] {
  margin-right: 0.5rem;
}
input[type=checkbox][data-v-23bba763] {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  width: 1.5rem;
  height: 1.5rem;
  border: 2px solid #e93f6f;
  border-radius: 3px;
  position: relative;
  cursor: pointer;
  outline: none;
  transition: background-color 0.3s, border-color 0.3s;
  margin-right: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.6rem;
}
input[type=checkbox][data-v-23bba763]:checked::before {
  content: "✓";
  color: #e93f6f;
  font-weight: bold;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
input[type=checkbox][data-v-23bba763]:disabled {
  border: 2px solid #003061;
  cursor: not-allowed;
}
.disabled-row[data-v-23bba763] {
  background-color: white;
  opacity: 0.5;
  pointer-events: none;
}